const constants = {
	ABOUT: 'About',
	AWSICON: 'Amazon Web Services Icon',
	BACKARROW: 'Back Arrow Icon',
	BACKTOSERVICES: 'Back to Services',
	BLOG: 'Blog',
	BLOGTITLE: 'Leading Thoughts...',
	CAPTCHA: 'Captcha',
	CHECKMARKICON: 'Checkmark Icon',
	CLICKHERE: 'Click Here',
	CONTACT: 'Contact',
	CONTACTCTS: 'Contact Us',
	CONTACTFORM: 'Contact Form',
	CORREGIDOR: 'Corregidor',
	CORREGIDORLOGOVIDEO: 'Corregidor Logo Video Background',
	CORREGIDORTECHNOLOGYSERVICES: 'Corregidor Technology Services',
	CORREGIDORTECHNOLOGYSERVICESLOGO: 'Corregidor Technology Services Logo',
	DOCKERICON: 'Docker Icon',
	EMAIL: 'Email: ',
	ENGINEERINGICON: 'Engineering Icon',
	ENVELOPEICON: 'Envelope Icon',
	EXPERIENCE: 'Experience',
	EXPERIENCEICON: 'Experience Icon',
	HOME: 'Home',
	INCLUSION: 'Inclusion',
	INCLUSIONCAPTION: 'Technology is our service. Opportunity: Our mission.',
	INSTAGRAMLINKTEXT: 'Link to Corregidor Tech on Instagram',
	INSTAGRAMURL: 'https://www.instagram.com/corregidortech/',
	JAVASCRIPTICON: 'JavaScript Icon',
	LEARNMORE: 'Learn More',
	LIGHTNINGBOLTICON: 'Lightning Bolt Icon',
	MESSAGE: 'Message: ',
	MESSAGESENT: 'Message Sent!',
	MICROSOFTICON: 'Microsoft Icon',
	MISSION: 'Mission',
	MISSIONICON: 'Mission Icon',
	NAME: 'Name: ',
	NODEICON: 'Node.js Icon',
	OUREXPERIENCE: 'Our Experience',
	PLEASECOMPLETECAPTCHA: 'Please Complete Captcha!',
	PLEASEPROVIDEVALIDEMAIL: 'Please provide a valid email address!',
	POLICY: 'Policy',
	PROPOSALICON: 'Proposal Icon',
	PROPOSALWRITING: 'Proposal Writing',
	QUALITY: 'Quality',
	QUALITYCAPTION: 'Agile but Structured. Documented. Responsive to feedback. Committed to improvement.',
	QUALITYDIAGRAM: 'Quality Process Diagram',
	QUALITYICON: 'Quality Icon',
	REACTICON: 'ReactJS Icon',
	SENDEMAILAPIURL: 'https://corregidorapi.azurewebsites.net/api/email/SendEmail',
	SENDING: 'Sending...',
	SERVICES: 'Services',
	SUBMIT: 'Submit',
	SYSENGAGILEQUOTE: 'Freelance web development. Professional system engineering consulting. Process-driven Agile.',
	SYSTEMENGINEERING: 'Systems Engineering',
	TECHNOLOGIES: 'Technologies',
	TECHNOLOGYSERVICES: 'Technology Services',
	TWITTERLINKTEXT: 'Link to Corregidor Tech on Twitter',
	TWITTERURL: 'https://twitter.com/corregidortech',
	VALUES: 'Values',
	VALUESICON: 'Values Icon',
	VALUESSTATEMENTLONG: 'Corregidor Technology Services provides system engineering consulting services',
	VALUESTATEMENTSHORT: 'CTS provides system engineering consulting services',
	WEBDEVELOPMENT: 'Web Development',
	WEBDEVICON: 'Web Development Icon',
	WHATWEDO: 'What We Do',
};

export default constants;
