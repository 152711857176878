import React from 'react';
import '../homeDesktop.scss';
import VideoComponent from './VideoComponent';

const HomemDesktopValueRow = () => {
    return(
        <div>
            <VideoComponent />            
        </div>
    )
};

export default HomemDesktopValueRow;